<template>
    <!-- Footer -->
    <!-- 訪客 -->
    <section v-if="$route.meta.mode === 'guest'" class="footer">
      <a class="visit" @click.prevent="goRegistration">
        <span><span>註冊/登入</span> 享有完整服務</span>
        <font-awesome-icon icon="fa-regular fa-hand-pointer" size="2x"></font-awesome-icon>
      </a>
    </section>
    <section v-else class="footer">
        <span style="display: none">{{ version }}</span>
        <a
            :class="classObject('Home')"
            @click.prevent="clickToRoute('Home')"
        >
            <img src="@/assets/icon/home.svg" />
            <img src="@/assets/icon/home-a.svg" />
            <span class="fS white">首頁</span>
        </a>
        <a
            :class="classObject('Service')"
            @click.prevent="clickToRoute('Service')"
        >
            <img src="@/assets/icon/service.svg" />
            <img src="@/assets/icon/service-a.svg" />
            <span class="fS white">市政互動</span>
        </a>
        <a
            :class="classObject('Scanner')"
            @click.prevent="clickToRoute('Scanner')"
        >
            <img src="@/assets/icon/scan.svg" />
            <img src="@/assets/icon/scan-a.svg" />
            <span class="fS white">掃碼</span>
        </a>
        <a
            :class="classObject('Map')"
            @click.prevent="clickToRoute('Map')"
        >
            <img src="@/assets/icon/store.svg" />
            <img src="@/assets/icon/store-a.svg" />
            <span class="fS white">優惠地圖</span>
        </a>
        <a
            :class="classObject('Profile')"
            @click.prevent="clickToRoute('Profile')"
        >
            <img src="@/assets/icon/user.svg" />
            <img src="@/assets/icon/user-a.svg" />
            <span class="fS white">會員</span>
        </a>
    </section>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "Footer",
    props: {
        routeTo: String
    },
    data: function () {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            currentTab: "home",
            tabs: ["Home", "Service", "Scanner", "Map", "Profile"],
            disableTabs: []
        };
    },
    computed: {
        ...mapState(["currentPath"]),
        version() {
            return process.env.PACKAGE_VERSION;
        }
    },
    mounted() {
        this.tabs = this.tabs.map((tab) => tab.toUpperCase());
        this.disableTabs = this.disableTabs.map((tab) => tab.toUpperCase());
        this.currentTab = this.currentPath;
    },
    methods: {
        classObject(tab) {
            tab = tab.toUpperCase();
            return {
                icon: true,
                iconButton: true,
                active: this.currentTab.toUpperCase() === tab,
                disabled: this.disableTabs.includes(tab)
            };
        },
        clickToRoute(path) {
            if (this.$route.meta.mode === 'guest') {
                return;
            } else {
                this.currentTab = this.$route.path.slice(1);
                if(!(path.toUpperCase() == this.currentTab.toUpperCase())) {
                    this.currentTab = path;
                    this.$router.push(`/${path.toLowerCase()}`);
                }
            }
            
        },
        goRegistration() {
            // this.$router.push('/home');
            console.log('this.$router.query', this.$route.query)
            qcsso
                .init({
                    appId: process.env.VUE_APP_ID, // tycard staging man
                    params: { referralCode: this.$route.query.referralCode || "" }
                })
                .then(() => {
                    qcsso.login({
                        redirectUrl: `${process.env.VUE_APP_HOST}${process.env.BASE_URL}${this.$route.query.redirectPath || ''}`
                    });
                });
        }
    },
    watch: {
        routeTo: function(val) {
            console.log("routeTo: ", val)
        },
        currentPath(val) {
            this.currentTab = val;
        }
    }
};
</script>


